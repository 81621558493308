import { Helmet } from 'react-helmet-async';
import { Stack } from '@mui/system';
import { Typography } from '@mui/material';

import CasErrorBoundary from '../CasErrorBoundary/CasErrorBoundary';
import CasPageContent, { ICasPageContent } from './CasPageContent';

export interface ICasPage extends ICasPageContent {
  pageTitle: string;
  title: string;
  titleSuffix?: string | JSX.Element;
  pageActions?: any;
  titleClassName?: string;
}

export default function CasPage({
  pageTitle,
  title,
  titleSuffix,
  pageActions=null,
  children,
  className = '',
  titleClassName= '',
}: ICasPage) {
  return (
    <>
      <Helmet>
        <title>{`${pageTitle} : ${title}`}</title>
      </Helmet>
      <CasErrorBoundary>
        <CasPageContent className={className}>
          <Stack direction="row">
            <Typography className={`page-title ${titleClassName}`} variant="h4" width={1} gutterBottom>
              {title}
              {titleSuffix}
            </Typography>
            { pageActions}
          </Stack>
          {children}
        </CasPageContent>
      </CasErrorBoundary>
    </>
  );
}
