import { lazy } from 'react';
import { ICONS } from '../navigationIcons';

const NavigatorPage = lazy(() => import('src/compass/pages/riskManagement/NavigatorPage'));
const InventoryPage = lazy(() => import('src/compass/pages/riskManagement/InventoryPage'));
const FeederIndexPage = lazy(() => import('src/compass/pages/riskManagement/FeederIndexPage'));

const SceReportsPage = lazy(() => import('src/compass/pages/riskManagement/SceReportsPage'));
const CommunicationsPage = lazy(
  () => import('src/compass/pages/riskManagement/CommunicationsPage')
);

export const RiskManagementMenu = [
  {
    menuTitle: 'Risk Management',
    path: 'riskmanagement',
    icon: ICONS.user,
    subMenus: [
      {
        title: 'Communications',
        path: 'communications',
        element: <CommunicationsPage />,
        permission: 'COMMUNICATIONS',
      },
      {
        title: 'Feeder Index',
        path: 'feederindex',
        element: <FeederIndexPage />,
        permission: 'FEEDER_INDEX',
      },
      {
        title: 'Inventory',
        path: 'inventory',
        element: <InventoryPage />,
        permission: 'INVENTORY',
      },
      {
        title: 'Navigator',
        path: 'navigator',
        element: <NavigatorPage />,
        permission: 'NAVIGATOR',
      },
      {
        title: 'SCE Reports',
        path: 'scereports',
        element: <SceReportsPage />,
        permission: 'SCE_REPORTS',
      },
    ],
  },
];
